import {
    Col, Row, Space, Table,
} from 'antd';
import { useContext, useEffect } from 'react';

import InitialColumns from './initialColumns';
import Filters from './Filters';
import { GuidesContext } from '../context/guides.context';

const GuidesTable = () => {
    const INITIAL_COLUMNS: any = InitialColumns();

    const {
        loading, total, paginator, data, onPageChange, fetchData,
        startDate, endDate, handleStatus, handleDate, searchParams,
    } = useContext(GuidesContext);

    const { limit, skip } = paginator;

    const statusString = searchParams.get('status');
    const currentTab = searchParams.get('tab');

    useEffect(() => {
        if (currentTab !== 'routes') return;
        fetchData();
    }, [startDate, endDate, statusString, currentTab]);

    return (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <Row justify="end">
                <Col>
                    <Filters
                        searchParams={searchParams}
                        startDate={startDate}
                        endDate={endDate}
                        handleStatus={handleStatus}
                        handleDate={handleDate}
                    />
                </Col>
            </Row>
            <Table
                loading={loading}
                columns={INITIAL_COLUMNS}
                dataSource={data}
                pagination={{
                    total,
                    current: skip / limit + 1,
                    pageSize: limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 1500 }}
                rowKey="_id"
            />
        </Space>
    );
};

export default GuidesTable;
